"use strict";
//import 'jquery/dist/jquery.min';
require('es6-promise').polyfill();

window.$ = window.jQuery = require('jquery');
require('./foundation');
import Vue from 'vue';

const searchInputComponent = () => import(/* webpackChunkName: 'js/part/searchinputcomponent.20200214' */ './components/searchInput');
const homeButtonsComponent = () => import(/* webpackChunkName: 'js/part/homebuttonscomponent.20200214' */ './components/homeButtons');
const faqBoxWidgetComponent = () => import(/* webpackChunkName: 'js/part/faqboxvidget.20200214' */ './components/faqBoxWidget');
const checkAnswerComponent = () => import(/* webpackChunkName: 'js/part/checkanswer.20200214' */ './components/checkAnswer');
const checkAnswerRelatedComponent = () => import(/* webpackChunkName: 'js/part/checkanswerrelated.20200214' */ './components/checkAnswerRelated');
const askQuestionComponent = () => import(/* webpackChunkName: "js/part/askquestion.20200214" */ './components/askQuestion');
const usefulComponent = () => import(/* webpackChunkName: 'js/part/usefulcompoment.20200214' */ './components/useful');
const voteIndexComponent = () => import(/* webpackChunkName: "js/part/vote.20200214" */ './components/voteIndex');
const voteOptionsComponent = () => import(/* webpackChunkName: "js/part/vote.20200220" */ './components/voteOptions');
const voteResultsComponent = () => import(/* webpackChunkName: "js/part/vote.20200214" */ './components/voteResults');
// const quizIndexComponent = () => import(/* webpackChunkName: "js/part/quiz.20200214" */ './components/quizIndex');
// const quizQuestionComponent = () => import(/* webpackChunkName: "js/part/quiz.20200214" */ './components/quizQuestion');
// const felonyComponent = () => import(/* webpackChunkName: "js/part/felony.20200214" */ './components/felony');
// const contraceptionComponent = () => import(/* webpackChunkName: "js/part/contraception.20200214" */ './components/contraception');
// const contactFormComponent = () => import(/* webpackChunkName: "js/part/contactForm.20200214" */ './components/contactForm');
// const orderFormComponent = () => import(/* webpackChunkName: "js/part/orderForm.20200214" */ './components/orderForm');
// const hScrollerComponent = () => import(/* webpackChunkName: "js/part/hScroller.20200214" */ './components/hScroller');

var vue_app = new Vue({
    el: '#main',
    data: {
        show_ask_question: false,
        show_ask_question_small: false,
        open_left_canvas: false,
        category_list: false,
        is_active_array: [],
        is_active_sub_array: [],
        show_scrollTop_button: false,
        show_live_search: false,
        felony_id: null,
        quiz_check_answers_num_clicked: 0,
        quiz_num_correct: 0,
        quiz_result_num: 0,
        componentKey: 0,
        menu_links_search_query: '',
        menu_links_show_search: false,
        menu_links: [],
        menu_links_filtered: {'article': [], 'faq': []},
        opacity: false,
        vue_articles: [],
        show_hamburger_links : false,
    },
    components: {
        faqboxwidget: faqBoxWidgetComponent,
        searchinput: searchInputComponent,
        useful: usefulComponent,
        checkanswer: checkAnswerComponent,
        related : checkAnswerRelatedComponent,
        askquestion: askQuestionComponent,
        homebuttons: homeButtonsComponent,
        voteindex: voteIndexComponent,
        // quizindex: quizIndexComponent,
        // quizquestion: quizQuestionComponent,
        voteoptions: voteOptionsComponent,
        voteresults: voteResultsComponent,
        // felony: felonyComponent,
        // contraception: contraceptionComponent,
        // contactform: contactFormComponent,
        // orderform: orderFormComponent,
        // hscroller: hScrollerComponent,
    },
    methods: {
        isActiveInArray: function (index) {
            return (this.is_active_array.indexOf(index) !== -1);
        },
        toggleActiveInArray: function (index) {
            if (this.isActiveInArray(index)) {
                var pointer = this.is_active_array.indexOf(index);
                this.is_active_array.splice(pointer, 1);
            } else {
                this.is_active_array.push(index);
            }
        },
        isActiveInSubArray: function (index) {
            return (this.is_active_sub_array.indexOf(index) !== -1);
        },
        toggleActiveInSubArray: function (index) {
            console.log('srm',index);
            if (this.isActiveInSubArray(index)) {
                var pointer = this.is_active_sub_array.indexOf(index);
                this.is_active_sub_array.splice(pointer, 1);
            } else {
                this.is_active_sub_array.push(index);
            }
        },
        handleScroll() {
            // this.show_scrollTop_button = $('.sticky.is-stuck').length > 0;
        },
        handleScrollDelayed: function () {
            // setTimeout(this.handleScroll, 250);
        },
        setFelony: function (felony_id, event) {
            event.preventDefault();
            this.felony_id = felony_id;
        },
        checkQuizAnswers: function () {
            var num_correct = 0;
            window.quiz.questions.forEach(question => {
                var is_correct = question.answers.filter(answer => answer.is_correct === answer.checked);
                if (is_correct.length > 0) {
                    num_correct++;
                }
            });
            this.quiz_check_answers_num_clicked++;
            this.quiz_num_correct = num_correct;

            var ratio = this.quiz_num_correct / window.quiz.questions.length;

            if (ratio < 0.33) {
                this.quiz_result_num = 1;
            } else if (ratio < 0.66) {
                this.quiz_result_num = 2;
            } else if (ratio < 1) {
                this.quiz_result_num = 3;
            } else {
                this.quiz_result_num = 4;
            }

        },
        updateShowLiveSearch(e) {
            // Prevent campaign image overlap of live search.
            // Set z-index:-1 on campaign image if live search is active.
            this.show_live_search = e;
        },
        showCategoryDesktopList() {
            this.category_list = true;
        },
        loadMenuLinks: function () {
            var comp = this;
            if (this.menu_links.length === 0) {
                $.ajax({
                    url: '/ajax_handler.php',
                    dataType: 'json',
                    type: 'GET',
                    data: {
                        action: 'load-menu-links'
                    },
                    success: function (response) {
                        comp.menu_links = response;
                    }
                });
            }
        },
        searchLinks: function () {
            var query = this.menu_links_search_query.trim().toLowerCase();
            if (query.length > 0) {
                this.menu_links_show_search = true;

                // Loop through all list items, find the ones who matches the search query
                var filtered = [];
                this.menu_links.article.forEach(function (link) {
                    if (link.title.toLowerCase().indexOf(query) > -1) {
                        filtered.push(link);
                    }
                });
                this.menu_links_filtered.article = filtered;
                filtered = [];
                this.menu_links.faq.forEach(function (link) {
                    if (link.title.toLowerCase().indexOf(query) > -1) {
                        filtered.push(link);
                    }
                });
                this.menu_links_filtered.faq = filtered;

            } else {
                this.menu_links_show_search = false;
            }
        },
        loadMoreArticlesHandler: function (data) {
            $.each(data.items, function (key, val) {
                $('.load-more-target').append(val.html);
            });

            if (!data.is_more) {
                $('.load-more-articles').remove();
            }
        },
        loadMoreArticles() {
            var exclude_channel_objs = [];
            $.each($('[data-exclude-load-more]'), function (key, obj) {
                exclude_channel_objs.push($(obj).attr('data-exclude-load-more'));
            });
            var firstCategoryId = $('[data-id-category]').first();
            $.ajax({
                url: '/ajax/article/',
                dataType: 'json',
                type: 'GET',
                data: {
                    action: 'loadMoreArticles'
                    ,
                    exclude_channel_objs: exclude_channel_objs
                    ,
                    idCategory: (firstCategoryId ? firstCategoryId.attr('data-id-category') : null)
                },
                success: this.loadMoreArticlesHandler
            });
        }
        ,
        clearNav(event) {
            event.preventDefault();
            var d = new Date();
            d.setTime(d.getTime() - (24 * 60 * 60 * 1000)); // delete cookie, set to yesterday...
            var expires = "expires=" + d.toUTCString();
            // Clear searchInput cookie on logo click. Notice the / after | which is the page url.
            document.cookie = "live_search=|/;" + expires + ";path = /";
            window.location.href = '/';
        },
        topFaqCategoryClicked: function (category_id) {
            // if (category_id > 0) {
                var comp = this;
                $.ajax({
                    url: '/ajax_handler.php',
                    dataType: 'json',
                    type: 'GET',
                    data: {
                        action: 'get_articles_in_channel'
                        , category_id: category_id
                    },
                    success: function (response) {
                        if (response.length > 0) {
                            comp.vue_articles = response;
                        }
                    }
                });
            // } else {
            //     this.vue_articles = [];
            // }
        },
        getYoutubeID(url) {
            if (url === undefined) {
                return false;
            }
            url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            if (url[2] !== undefined) {
                var ID = url[2].split(/[^0-9a-z_\-]/i);
                return ID[0];
            }
            return false;
        },
        sendGTMEvent: function (obj, event) {

            if (event) {
                event.preventDefault()
            }

            if (obj.legacy && obj.url) {
                dataLayer.push({'event': 'inline-related-link', 'url': obj.url});
            }

            dataLayer.push({
                'event': 'GA event',
                'ec': obj.event.c,
                'ea': obj.event.a,
                'el': obj.event.l,
                'eventCallback': function () {
                    if (obj.url) {
                        window.location = obj.url;
                    }
                }
            });

        },
        sendGTMVirtualPageView(event, path, title) {
            window.dataLayer.push({
                event: event,
                page: {
                    path: path,
                    title: title
                }
            });
        },
    },
    mounted() {
        $(this.$el).foundation();
        // window.addEventListener("scroll", this.handleScroll);
        // //this.handleScroll();
        //
        // // Prevent rest of the page scrolling when scrolling in off-canvas
        // $('#offCanvasLeft').on('touchmove scroll',function(e) {
        //     e.stopPropagation();
        // });

        // if (window.location
        //     && window.location.hash
        //     && (window.location.href.indexOf('/chat/') !== -1
        //         || window.location.href.indexOf('/hjelp/') !== -1)) {
        //     var element = $('[data-hash-id="' + window.location.hash.substring(1) + '"]').first();
        //     if (element && element.length > 0) {
        //         this.toggleActiveInArray(parseInt(element.attr('data-index')));
        //         $([document.documentElement, document.body]).animate({
        //             scrollTop: element.offset().top
        //         }, 750);
        //     }
        // }
        this.topFaqCategoryClicked();
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        // eslint-disable-next-line
        $(this.$el).foundation.destroy();
    },
});